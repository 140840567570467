export const solNetwork = process.env.NEXT_PUBLIC_SOL_NETWORK || 'devnet';
export const CMID =
  process.env.NEXT_PUBLIC_CANDY_MACHINE_ID || '8apkgXxypgvjLCRkcybYnfFGmVCQotxF8QdBW6YpFXG6'; // devnet-mint1

const networkUris = {
  'mainnet-beta': 'https://peskypenguins.genesysgo.net/',
  mainnet: 'https://peskypenguins.genesysgo.net/',
  devnet: 'https://devnet.genesysgo.net/',
  // devnet: 'https://api.devnet.solana.com',
};

const config = {
  networkUris,
  solNetwork,
};

export const isDevnet = solNetwork === 'devnet';

export default config;
export function getNetworkUri() {
  return networkUris[config.solNetwork] || networkUris.devnet;
}
