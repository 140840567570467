import { useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import { useRouter } from 'next/router';
import Head from 'next/head';
import * as Fathom from 'fathom-client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SolanaProvider from '../components/SolanaProvider.js';
import '../styles/globals.css';

export { reportWebVitals } from 'next-axiom';

export default function NootopiaGame({ Component, pageProps }) {
  const router = useRouter();
  useEffect(() => {
    Fathom.load('ZLMSMQOK', {
      includedDomains: ['smugglers.nootopia.app'],
    });
    function onRouteChangeComplete() {
      Fathom.trackPageview();
    }
    // Record a pageview when route changes
    router.events.on('routeChangeComplete', onRouteChangeComplete);
    // Unassign event listener
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <title>Nootopia: Smuggler&apos;s Den</title>
      </Head>
      <ToastContainer position="top-center" />
      <RecoilRoot>
        <SolanaProvider>
          <Component {...pageProps} />
        </SolanaProvider>
      </RecoilRoot>
    </>
  );
}
